<template>
  <div>
    <v-footer app style="background: #fff;">
      <v-row justify="center" no-gutters>
          <v-col cols="12" sm="12" md="2" class="text-right mt-4 pa-0">
          <v-img height="20px" width="100%" src="./../../assets/footerLogo2.png" contain></v-img>
        </v-col>
        <v-col class="text-left mt-3 pa-0" cols="12" sm="12" md="5" lg="4">
            © <span style="font-family: MSemiBold; color: black; font-size: 15px;">{{ new Date().getFullYear() }}</span> <span style="font-family: MRegular; color: grey;">Powered by</span>  <a  style="text-decoration:none" href="http://leopardtechlabs.com">
             <span style="text-transform: uppercase; font-family: MSemiBold; color: black; font-size: 15px;"> Leopard Tech Labs Pvt. Ltd</span>
            </a>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>